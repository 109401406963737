import React from "react";
import { string } from "yup";
export default {
  EN: {
    header_title: "Annual report on CO2 emission reduction ",
    header_subtitle: "Issued by Preem Norge AS in connection with bulk supplies",
    infoBox1_title: "1. General information",
    infoBox1_customer: "Customer",
    infoBox1_deliveryPeriod: "Delivery period",
    infoBox1_deliveryMethod: "Delivery method",
    infoBox1_quantity: "Quantity",
    infoBox1_literBio: "liter",
    infoBox1_supplier: "Supplier",
    infoBox1_averageCO2reduction: "Average CO2 reduction",
    infoBox1_emissionSavings: "Emission savings",
    infoBox1_tonCO2equ: "ton CO2-ekv.",
    infoBox1_kgCO2equ: "kg CO2-ekv.",
    description_paragraph1:
        "Preem Norge AS declares that biofuel products covered by this document fulfills all sustainability criteria according to the Norwegian legislation as well as the European Union Renewable Energy Directive (RED) on the promotion of use of energy from renewable sources. The report has been audited by DNV.",
    description_paragraph2:
        "This report documents calculated emission- and reduction values for products Preem Norge AS has delivered in ",
    description_paragraph2a:
        ". The values are calculated in CO2 equivalents based on fossil reference values taken from the European Union Renewable Energy Directive (2,68 kg/liter gasoline and 3,02 kg/liter diesel). For the calculation of the reduction capacity, account has been taken of the transport of raw materials, any pre-treatment, production of the biofuel, transport to the warehouse and distribution to the customer.",
    description_paragraph3:
        "DNV has carried out an independent verification of the ",
    description_paragraph31:"  annual sustainability report on biofuels from Preem Norge AS to Miljødirektoratet. DNV has also verified that the volumes and calculations in this CO2 emission report are in line with the Preem report to Miljødirektoratet.",
    description_paragraph4: "DNV auditor statements are available at",
    description_paragraph5: "preem.no.",
    description_nameAndPosition: "Name and position",
    description_date: "Date",

    infoBox2_title: "2. Total Value",
    infoBox2_liter: "liter",
    infoBox2_and: "and",
    infoBox2_purchasedVolume: "Purchased volume",
    infoBlock_biofuels: "Biofuels",
    infoBlock_raw: "Raw material",
    infoBlock_origin: "Origin",
    infoBlock_volume: "Volume",
    infoBlock_co2: "CO2 reduction",
    infoBlock_cert: "Certification",
    infoBlock_adv: "Advanced",

    infoBox3_title: "3. Product values",
    infoBox3_subtitle1: "Preem Evolution Diesel",
    infoBox3_subtitle2: "Preem Diesel 7 % Bio",
    infoBox4_title: "4. Auditor statement",
    infoBox5_title: "5. Contact information",
    contact_company: " Preem Norge AS",
    contact_company2: "(919 502 193)",
    contact_address: "Lysaker Torg 8, 1366 Lysaker",
    contact_email: "kundeservice@preem.no",
    contact_phone: "+47 64 80 84 44 / 04211 ",
    label_reportID: "Report ID",
    label_period: "Delivery period",
    label_date: "Date issued",
    label_customer: "Customer",
    label_supplier: "Supplier",
    label_fuel: "Fuel volume",
    label_emission: "CO2 emissions",
    label_emission2: "CO2 emission",
    label_reduction: "CO2 emission reduction",
    label_volumn: "Volume",
    label_energy: "Energy content",

    footnote1: "Fossil reference emission for the total fuel volume is ",
    footnote2: "Fossil reference emission for the product volume is ",
    footnote3:
        "Fossil reference emission for the product volume is 2 777,37 tCO2e",
  },
  NO: {
    header_title: "Årlig dokumentasjon for CO2-reduksjon",
    header_subtitle: "Utstedt av Preem Norge AS i tilknytning til leveranser i bulk",
    infoBox1_title: "1. Generell informasjon",
    infoBox1_customer: "Kunde",
    infoBox1_deliveryPeriod: "Leveringsperiode",
    infoBox1_quantity: "Volum",
    infoBox1_literBio: "liter biodrivstoff",
    infoBox1_deliveryMethod: "Leveringsmåte",
    infoBox1_supplier: "Leverandør",
    infoBox1_averageCO2reduction: "CO2-reduksjon (gjennomsnitt)",
    infoBox1_emissionSavings: "Utslippsreduksjon",
    infoBox1_tonCO2equ: "tonn CO2-ekvivalenter",
    infoBox1_kgCO2equ: "kg CO2-ekv.",
    description_paragraph1:
        "Preem Norge AS erklærer at biodrivstoff omfattet av denne rapporten oppfyller alle bærekraftskriterier i produktforskriften kapittel 3 og EU sitt fornybardirektiv (RED) artikkel 17. Rapporten er kontrollert av DNV.",
    description_paragraph2:
        "Rapporten dokumenterer beregnede utslipps- og reduksjonsverdier for produkter Preem Norge AS har levert i ",
    description_paragraph2a:
        ". Verdiene er beregnet i CO2-ekvivalenter ut fra fossile referanseverdier hentet fra RED-direktivet (2,68 kg/liter bensin og 3,02 kg/liter diesel). For utregning av reduksjonsevnen er det tatt hensyn til transport av råvarer, eventuell forhåndbehandling, produksjon av biodrivstoffet, transport til lager og distribusjon til kunde.",

    description_paragraph3:
        "DNV har gjennomført en uavhengig verifikasjon av Preem Norge AS' ",
    description_paragraph31: "-rapportering av bærekraftskriterier for biodrivstoff. DNV har også kontrollert at biodrivstoffvolumet i rapportene er i overensstemmelse med rapporteringen til Miljødirektoratet.",
    description_paragraph4: "DNVs revisorerklæringer er tilgjengelige på ",
    description_paragraph5: "preem.no.",
    description_nameAndPosition: "Navn og posisjon",
    description_date: "Dato",

    infoBox2_title: "2. Totale verdier",
    infoBox2_liter: "liter",
    infoBox2_and: "og",
    infoBox2_purchasedVolume: "Levert",
    infoBlock_biofuels: "Biodrivstoff",
    infoBlock_raw: "Råstoff ",
    infoBlock_origin: "Opprinnelse",
    infoBlock_volume: "Volum",
    infoBlock_co2: "CO2-reduksjon",
    infoBlock_cert: "Sertifisering",
    infoBlock_adv: "Avansert",

    infoBox3_title: "3. Verdier pr. produkt",
    infoBox3_subtitle1: "Preem Evolution Diesel",
    infoBox3_subtitle2: "Preem Diesel 7 % Bio",
    infoBox4_title: "4. Revisorerklæring",
    infoBox5_title: "5. Kontaktinformasjon",
    contact_company: " Preem Norge AS",
    contact_company2: "(919 502 193)",
    contact_address: "Lysaker Torg 8, 1366 Lysaker",
    contact_email: "kundeservice@preem.no",
    contact_phone: "+47 64 80 84 44 / 04211 ",
    label_reportID: "Rapport ID",
    label_period: "Periode",
    label_date: "Dato utstedt",
    label_customer: "Mottaker",
    label_supplier: "Leverandør",
    label_fuel: "Volum drivstoff",
    label_emission: "CO2-utslipp",
    label_emission2: "CO2-utslipp",
    label_reduction: "CO2-reduksjon",
    label_volumn: "Volum",
    label_energy: "Energiinnhold",

    footnote1: "Fossilt referanseutslipp for totalvolumet er ",
    footnote2: "Fossilt referanseutslipp for produktvolumet er ",
    footnote3: "Fossilt referanseutslipp for produktvolumet er 2 777,37 tCO2e",
  },
};
